import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { coffee } from '@fortawesome/free-brands-svg-icons'
export default function  Footer () {
            
return (
    <div>
              <div className='footer--header'>
                <p><i class="fa-solid fa-location-dot"></i> Assinie Mafia</p>
                <p><i class="fa-solid fa-globe"></i> www.miami-beach.com</p>
                <p><i class="fa-solid fa-envelope"></i> miami@beach.com</p>
                <p><i class="fa-brands fa-whatsapp"></i> +225 07 77 88 00 82 </p>
                <p><i class="fa-brands fa-facebook"></i> Facebook</p>
                <p><i class="fa-brands fa-instagram"></i> Instagram</p>
                
        </div>
          <div className='footer'>
            <p>Copyright © 2022,Miami-beach </p>
      </div>
    </div>

    );
}

